module.exports = [{
      plugin: require('/Users/juanvasquez/Sites/work/endo-xiaflex-email-confirmation-clean-pencil/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":""},
    },{
      plugin: require('/Users/juanvasquez/Sites/work/endo-xiaflex-email-confirmation-clean-pencil/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/juanvasquez/Sites/work/endo-xiaflex-email-confirmation-clean-pencil/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
